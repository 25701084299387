import moment from "moment";

export const StoreUserData = (userValues) => {
  localStorage.setItem("userData", JSON.stringify(userValues));
};

export const GetUserData = () => {
  try {
    const items = localStorage.getItem("userData");
    return JSON.parse(items, null, -1);
  } catch (err) {
    return [];
  }

  // return JSON.parse(localStorage.getItem('userData'));
};

export const StoreForgotEmailandId = (values) => {
  localStorage.setItem("forgotDetails", JSON.stringify(values));
};

export const GetForgotEmailandId = () => {
  return JSON.parse(localStorage.getItem("forgotDetails"));
};

// export const GetUserName = () => {
//     return localStorage.getItem('first_name')
// }
// export const GetUserData = () => {
//     return localStorage.getItem('token')
// }

// export const GetUserId = () => {
//     return localStorage.getItem('user_id')
// }

export const RemoveUserData = () => {
  localStorage.removeItem("userData");
};

export const RemoveUserId = () => {
  localStorage.removeItem("user_id");
};
// stored json data
export const StoreDatesAndRooms = (data) => {
  localStorage.setItem("singleBookingDetails", JSON.stringify(data));
};
export const StoreCheckIn = (data) => {
  sessionStorage.setItem("checkIn", data);
};
export const StoreCheckOut = (data) => {
  sessionStorage.setItem("checkOut", data);
};
export const GetStoreCheckIn = (data) => {
  const checkIn = sessionStorage.getItem("checkIn");
  if (checkIn) {
    return moment(checkIn).toDate();
  }
  return null;
};
export const GetStoreCheckOut = (data) => {
  const checkOut = sessionStorage.getItem("checkOut");
  if (checkOut) {
    return moment(checkOut).toDate();
  }
  return null;
};
export const GetStoreDatesAndRooms = () => {
  return JSON.parse(localStorage.getItem("singleBookingDetails"));
};
export const ClearCheckInCheckOut = () => {
  sessionStorage.removeItem("checkIn");
  sessionStorage.removeItem("checkOut");
};
export const StoreHotelIdAndRoomId = (data) => {
  localStorage.setItem("hotelId", JSON.stringify(data));
};

export const GetHotelIdAndRoomId = () => {
  return JSON.parse(localStorage.getItem("hotelId"));
};

export const GetGroupBookingSelectedHotels = () => {
  const selectedHotels = localStorage.getItem("selectedHotels");
  if (!!selectedHotels) {
    return JSON.parse(selectedHotels);
  }
  return [];
};

export const SetGroupBookingSelectedHotels = (selectedHotels) => {
  if (selectedHotels?.length > 0) {
    localStorage.setItem("selectedHotels", JSON.stringify(selectedHotels));
  } else {
    localStorage.removeItem("selectedHotels");
  }
};

// export const StoreBookingTempId = (data) => {
//     localStorage.setItem('bookingTempIdAndAdult', JSON.stringify(data));
// }

// export const GetBookingTempId = () => {
//     return JSON.parse(localStorage.getItem('bookingTempIdAndAdult'));
// }
