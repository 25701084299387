import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { renderRoutes, privateRenderRoutes } from "./config/routes";
import "./assets/css/normalize.css";
import "./assets/css/components.css";
import "./assets/css/groople-lb.css";
import "./assets/css/utility.css";
import ContextProvider from "context/provider";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "components/ErrorBoundary";
import ScrollToTop from "ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <ErrorBoundary>
          <ToastContainer />
          <ContextProvider>
            <ScrollToTop />
            <Switch>
              {renderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  render={(props)=> <route.component {...props} title={route.title || "Groople.com | Customized Hotel Group Quotes."}/>}
                />
              ))}
              {/* <CheckPrivileges> */}
              {privateRenderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  render={(props)=> <route.component {...props} title={route.title || "Groople.com | Customized Hotel Group Quotes."}/>}
                />
              ))}
              {/* </CheckPrivileges> */}
            </Switch>
          </ContextProvider>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
