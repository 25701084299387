import { lazy } from "react";

export const routes = {
    blank_page: {
        path: "/blank-page",
        component: lazy(() => import("../pages/BlankPage/BlankPage")),
        exact: true,
      },
      index: {
        path: "/",
        component: lazy(() => import("../pages/IndexPage/IndexPage")),
        exact: true,
        title: "Simplified Ad Free Hotel Bookings",
      },
      search_page: {
        path: "/search",
        component: lazy(() => import("../pages/SingleBookingList/SingleBookingList")),
        exact: true,
      },
      booking_detail_page: {
        path: "/hotel-details/:slug",
        component: lazy(() => import("../pages/SingleBookingDetailPage/SingleBookingDetailPage")),
        exact: true,
      },
      group_booking_detail_page: {
        path: "/group-booking/hotel-details/:slug",
        component: lazy(() => import("../pages/SingleBookingDetailPage/SingleBookingDetailPage")),
        exact: true,
      },
      groupBooking: {
        path: "/group-booking",
        component: lazy(() => import("../pages/GroupBookingPage/GroupBookingPage")),
        exact: true,
        title:"Personalized Group Hotel Bookings",
      },
      groupBookingDetail: {
        path: "/group-booking-booking-details",
        component: lazy(() => import("../pages/GroupBookingDetails/GroupBookingDetails")),
        exact: true,
      },
      groupBookingThankYou: {
        path: "/group-booking-thank-you",
        component: lazy(() => import("../pages/GroupBookingThankYou/GroupBookingThankYou")),
        exact: true,
      },
      groupBookingEstimatedTime: {
        path: "/estimatedtime",
        component: lazy(() => import("../pages/GroupBookingEstimatedTime/GroupBookingEstimatedTime")),
        exact: true,
      },
      groupBookingEstimatedTimeThankYou: {
        path:"/estimated-time-thank-you",
        component: lazy(()=>import("../pages/GroupBookingEstimatedTime/GroupBookingEstimatedTimeThankYou")),
        exact: true,
      },
      group_search_page: {
        path: "/group-booking-search",
        component: lazy(() => import("../pages/GroupBookingSearch/GrouBookingSearch")),
        exact: true,
      },
      group_air: {
        path: "/group-air",
        component: lazy(() => import("../pages/GroupAir/GroupAir")),
        exact: true,
      },
      group_type: {
        path: "/group-type",
        component: lazy(() => import("../pages/GroupType/GroupType")),
        exact: true,
      },
      hotel_repsonse: {
        path: "/response",
        component: lazy(() => import("../pages/HotelResponseForm/HotelResponseForm")),
      },
      group_booking_selected_hotel: {
        path: "/groupBooking",
        component: lazy(() => import("../pages/GroupBookingSelectedHotel/GroupBookingSelectedHotelPage")),
      },
      group_booking_payment: {
        path: "/payment",
        component: lazy(() => import("../pages/GroupBookingPayment/GroupBookingPayment")),
      },
      group_booking_final_payment: {
        path: "/final-payment",
        component: lazy(() => import("../pages/GroupBookingFinalPayment/GroupBookingFinalPayment.js")),
      },
      groupBooking_payathotel:{
        path: "/payathotel",
        component: lazy(() => import("../pages/GroupBookingPayAtHotel/GroupBookingPayAtHotel")),
      },
      about_us: {
        path: "/about-us",
        component: lazy(() => import("../pages/AboutUs/AboutUs")),
        exact: true,
      },
      recent_news: {
        path: "/recent-news",
        component: lazy(() => import("../pages/RecentNews/RecentNews")),
        exact: true,
      },
      testimonial: {
        path: "/testimonials",
        component: lazy(() => import("../pages/Testimonials/Testimonials")),
        exact: true,
      },
      top_destination: {
        path: "/top-destination",
        component: lazy(() => import("../pages/TopDestination/TopDestination")),
        exact: true,
      },
      blog: {
        path: "/blog",
        component: lazy(() => import("../pages/Blog/Blog")),
        exact: true,
      },
      contact_us: {
        path: "/contact-us",
        component: lazy(() => import("../pages/ContactUs/ContactUs")),
        exact: true,
      },
      register : {
        path: "/register",
        component: lazy(() => import("../pages/Register/Register")),
        exact: true,
      },
      login: {
        path: "/login",
        component: lazy(() => import("../pages/Login/Login")),
        exact: true,
      },
      changePassword: {
        path: "/change-password",
        component: lazy(() => import("../pages/ChangePassword/ChangePassword")),
        exact: true,
      },
      verify: {
        path: "/verify",
        component: lazy(() => import("../pages/Verify/Verify")),
        exact: true,
      },
      forgot_password: {
        path: "/forgot-password",
        component: lazy(() => import("../pages/ForgotPassword/ForgotPassword")),
        exact: true,
      },
      reset_password: {
        path: "/reset-password",
        component: lazy(() => import("../pages/ResetPassword/ResetPassword")),
        exact: true,
      },
      email_verification: {
        path: "/verification",
        component: lazy(() => import("../pages/OtpVerification/OtpVerification")),
        exact: true,
      },
      my_account: {
        path: "/my-account",
        component: lazy(() => import("../pages/MyAccount/MyAccount")),
        exact:true,
      },
      my_booking: {
        path: "/my-booking",
        component: lazy(() => import("../pages/MyBookings/MyBooking")),
        exact:true,
      },
      my_booking_detail: {
        path: "/my-booking/:id",
        component: lazy(() => import("../pages/MyBookingDetail/MyBookingDetail")),
        exact:true,
      },
      edit_booking: {
        path: "/my-booking/edit-booking/:id",
        component: lazy(() => import("../pages/EditBooking/EditBooking")),
        exact:true,
      },
      edit_booking_price_summary:{
        path:"/my-booking/edit-booking/price-summary/:id",
        component: lazy(()=>import("../pages/EditBookingPricePage/EditBookingPricePage")),
        exact:true,
      },
      billing: {
        path: "/billing",
        component: lazy(() => import("../pages/Billings/Billing")),
        exact:true,
      },
      single_booking_price: {
        path: "/single-booking-price",
        component: lazy(() => import("../pages/SingleBookingPricePage/SingleBookingPrice")),
        exact:true
      },
      single_booking_confirmation: {
        path: "/single-booking-confirmation",
        component: lazy(() => import("../pages/SingleBookingConfirmation/SingleBookingConfirmation")),
        exact:true,
      },
      single_booking_thank_you: {
        path: "/single-booking-thank-you/:id",
        component: lazy(() => import("../pages/SingleBookingThankYou/SingleBookingThankYou")),
        exact:true,
      },
      single_booking_failed: {
        path: "/booking-failed",
        component: lazy(() => import("../pages/SingleBookingFailed/SingleBookingFailed")),
        exact:true,
      },
      
      las_vegas: {
        path: "/top-destination/las-vegas",
        component: lazy(() => import("../pages/LasVegasPage/LasVegas")),
        exact:true
      },
      atlanta: {
        path: "/top-destination/atlanta",
        component: lazy(() => import("../pages/AtlantaPage/Atlanta")),
        exact:true
      },
      chicago: {
        path: "/top-destination/chicago",
        component: lazy(() => import("../pages/ChicagoPage/Chicago")),
        exact:true
      },
      new_orleans: {
        path: "/top-destination/new-orleans",
        component: lazy(() => import("../pages/NewOrleansPage/NewOrleans")),
        exact:true
      },
      new_york: {
        path: "/top-destination/new-york",
        component: lazy(() => import("../pages/NewYorkPage/NewYork")),
        exact:true
      },
      orlando: {
        path: "/top-destination/orlando",
        component: lazy(() => import("../pages/OrlandoPage/Orlando")),
        exact:true
      },
      san_francisco: {
        path: "/top-destination/san-francisco",
        component: lazy(() => import("../pages/SanFranciscoPage/SanFrancisco")),
        exact:true
      },
      washington_dc: {
        path: "/top-destination/washington-dc",
        component: lazy(() => import("../pages/WashingTonDcPage/WashingTonDc")),
        exact:true
      },
      college_group: {
        path: "/groups/college-or-university-group-travel",
        component: lazy(() => import("../pages/CollegeGroup/CollegeGroup")),
        exact: true
      },
      corporate_group: {
        path: "/groups/corporate-incentive-travel",
        component: lazy(() => import("../pages/CorporateGroup/CorporateGroup")),
        exact: true
      },
      religious_group: {
        path: "/groups/religious",
        component: lazy(() => import("../pages/ReligiousGroup/ReligiousGroup")),
        exact: true
      },
      school_group: {
        path: "/groups/school",
        component: lazy(() => import("../pages/SchoolGroup/SchoolGroup")),
        exact: true
      },
      sport_group: {
        path: "/groups/sport",
        component: lazy(() => import("../pages/SportGroup/SportGroup")),
        exact: true
      },
      demo_paypal_payment: {
        path: "/demo/paypal-payment",
        component: lazy(() => import("../pages/DemoPayment/DemoPaypalPayment")),
        exact: true
      },
      terms_and_condition: {
        path: "/terms-and-conditions",
        component: lazy(() => import("../pages/TermsAndConditions/TermsAndConditions")),
        exact: true
      },
      privacy_policy: {
        path: "/privacy-policy",
        component: lazy(() => import("../pages/PrivacyPolicy/PrivacyPolicy")),
        exact: true
      },
      cookie_policy: {
        path: "/cookie-policy",
        component: lazy(() => import("../pages/CookiePolicy/CookiePolicy")),
        exact: true
      },
      site_map: {
        path: "/site-map",
        component: lazy(() => import("../pages/SiteMap/SiteMap")),
        exact: true
      },
      blog_detail_page: {
        path: "/blog-detail/:slug",
        component: lazy(() => import("../pages/BlogDetailPage/BlogDetailPage")),
        exact: true,
      },

};

export const renderRoutes = Object.entries(routes);

export const privateroutes = {
  dashboard: {
    path: "/dashboard",
    component: lazy(() => import("../pages/IndexPage/IndexPage")),
    exact: true,
    menuname: 'Dashboard'
  },
};

export const privateRenderRoutes = Object.entries(privateroutes);