import { GetStoreCheckIn, GetStoreCheckOut, StoreCheckIn, StoreCheckOut } from "config/storage";
import moment from "moment";
import { createContext, useContext, useState } from "react";

const TripContext = createContext();

export const TripProvider = ({ children }) => {
  const defaultCheckInDate = moment().toDate();
  defaultCheckInDate.setDate(defaultCheckInDate.getDate() + 7);
  const defaultCheckoutDate = moment(defaultCheckInDate).toDate();
  defaultCheckoutDate.setDate(defaultCheckoutDate.getDate() + 2);

  const sessionStorageCheckIn = GetStoreCheckIn();
  const sessionStorageCheckOut = GetStoreCheckOut();

  const [checkIn, setCheckIn] = useState(sessionStorageCheckIn || defaultCheckInDate);
  const [checkOut, setCheckOut] = useState(sessionStorageCheckOut || defaultCheckoutDate);
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState("");
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selected, setSelected] = useState();
  const [roomCount, setRoomCount] = useState(null);
  const [number, setNumber] = useState(1);
  const [selectOption, setSelectOption] = useState({
    room: 1,
    adult: 1,
    children: 0,
  });
  const [countryCode, setCountryCode] = useState("");
  const handleCheckIn = (date) =>{
      StoreCheckIn(date);
      setCheckIn(date);
  }
  const handleCheckOut = (date) =>{
    StoreCheckOut(date);
    setCheckOut(date);
  }
  return (
    <TripContext.Provider
      value={{
        checkIn,
        setCheckIn: handleCheckIn,
        checkOut,
        setCheckOut: handleCheckOut,
        options,
        setOptions,
        values,
        setValues,
        selectedDestination,
        setSelectedDestination,
        selected,
        setSelected,
        number,
        setNumber,
        roomCount, 
        setRoomCount,
        selectOption, 
        setSelectOption,
        countryCode,
        setCountryCode,
      }}
    >
      {children}
    </TripContext.Provider>
  );
};
export const TripState = () => {
  return useContext(TripContext);
};

export default TripContext;
