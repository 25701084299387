import React from "react";
import { toast } from "react-toastify";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error.message || "Something went wrong" };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (process.env.NODE_ENV === "development") {
      console.log(error, errorInfo);
    }
  }

  render() {
    if (!!this.state.error) {
      toast.error(this.state.error);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
